import { TenantConfig } from '~/tenants/common/registry';
import { schema_latest_version } from '~/lib/zod';
import z, { ZodObject } from 'zod';
import { DistanceAddressSchema } from '~/lib/model';

export const SCHEMA_FILE = z.object({
  name: z.string(),
  mime: z.string(),
  url: z.string().url(),
  bytes: z.coerce.number(),
});

export function schema_order_quick(tenant: TenantConfig, draft = false) {
  const [job, ...jobs] = Object.values(tenant.performables).map((p) =>
    z.object({
      performable_id: z.literal(p.config.id),
      metadata: p.config.schema[schema_latest_version(p.config.schema)].optional(),
    }),
  );

  const order = tenant.orderSchema[schema_latest_version(tenant.orderSchema)];

  return z
    .object({
      customer_id: z.string({
        required_error: 'Please choose a customer.',
      }),
      location: z.string(),
      metadata: draft ? z.record(z.string(), z.any()) : order,
      address: DistanceAddressSchema,
      performables: z
        .discriminatedUnion('performable_id', [job, ...jobs])
        .array()
        .nonempty({ message: 'Please add at least one job.' }),
    })
    .strict();
}

export type SchemaOrderQuick = z.infer<ReturnType<typeof schema_order_quick>>;

export function schema_order_quick_appointment(tenant: TenantConfig, draft = false) {
  return schema_order_quick(tenant, draft).extend({
    provider_id: z.string({ required_error: 'Please choose a provider.' }),
    start: z.coerce.date({ required_error: 'Please provide a start time.' }),
  });
}

export type SchemaOrderQuickAppointment = z.infer<ReturnType<typeof schema_order_quick_appointment>>;
